import React from 'react';
// import './App.css';

import { createBrowserRouter, RouterProvider, Route } from 'react-router-dom';
import Root from "../../routes/root";
import Docview, {docviewLoader} from "../../routes/docview";
import AfterSend from "../../routes/aftersend";
import Expired from "../../routes/expired";
import Create, {createLoader} from "../../routes/create";

//routes: Docs, Dashboard, Create, Account
const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "docview/:docid",
        element: <Docview />,
        loader: docviewLoader,
      },
      {
        path: "aftersend",
        element: <AfterSend />,
      },
      {
        path: "expired",
        element: <Expired />,
      },
      {
        path: "create",
        element: <Create />,
      },
    ]
  },
]);

export default function App() {
  return (
    <RouterProvider router={router} />
  )
}