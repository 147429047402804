import React, { useState, useEffect, useLayoutEffect, useReducer, useRef } from 'react';
// import Redline from '../Components/Redline/Redline.js';
import VsVersionSelector from '../Components/VsVersionSelector/VsVersionSelector.js';
// import CommentView from '../Components/CommentView/CommentView.js';
import UserChips from '../Components/UserChips/UserChips.js';
import ReadOnlyIndicator from '../Components/ReadOnlyIndicator/ReadOnlyIndicator.js';
import SendBox from '../Components/SendBox/SendBox.js';
import SummaryBox from '../Components/SummaryBox/SummaryBox.js';
// import MessageThread from '../Components/MessageThread/MessageThread.js';
import { cleanDoc } from '../Components/Blackline/cleandoc';

import { useLoaderData, useNavigate } from 'react-router-dom';
import { collection, doc, addDoc, getDoc, setDoc, writeBatch } from "firebase/firestore";
import db from '../firebase.js';
import './docview.css';

import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Input from '@mui/material/Input';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
//import Menu from '@mui/material/Menu';
//import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
//import AdbIcon from '@mui/icons-material/Adb';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ForwardIcon from '@mui/icons-material/Forward';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import LinkIcon from '@mui/icons-material/Link';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AutoRenewIcon from '@mui/icons-material/Autorenew';
import SearchIcon from '@mui/icons-material/Search';
import SendIcon from '@mui/icons-material/Send';
import ErrorIcon from '@mui/icons-material/Error'
import Badge from '@mui/material/Badge';
import MoreIcon from '@mui/icons-material/MoreVert';
import Slider from '@mui/material/Slider';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import SvgIcon from '@mui/material/SvgIcon';
import CloseIcon from '@mui/icons-material/CancelPresentationRounded';
import UndoIcon from '@mui/icons-material/Undo';
import MinimizeIcon from '@mui/icons-material/Minimize';
import AddIcon from '@mui/icons-material/AddCircleOutlineRounded';
import AddCommentIcon from '@mui/icons-material/AddComment';
import { uuidv4 } from '@firebase/util';
import Blackline from '../Components/Blackline/Blackline.js';
import { parseStyleData, stringifyStyleObj } from '../Components/Blackline/Blackline.js';
import { Autorenew } from '@mui/icons-material';
import LockIcon from '@mui/icons-material/Lock';

export default function Docview() {
    const [redlineReadOnly, setRedlineReadOnly] = useState(false);
    const [loadData, setLoadData] = useState(useLoaderData());
    const [toField, setToField] = useState("");
    const [subjectField, setSubjectField] = useState("");
    const [messageField, setMessageField] = useState("");
    const [statusIcon, setStatusIcon] = useState(<AutoRenewIcon color="disabled" />);
    const [statusLabel, setStatusLabel] = useState("Loading...");
    const [activeColor, setActiveColor] = useState(0);
    const [editButtonsColor, setEditButtonsColor] = useState("primary");
    const [docState, setDocState] = useState(emptyDoc);
    //const [editorState, setEditorState] = useState({});
    const docStateRef = useRef();
    docStateRef.current = docState;
    const [selection, setSelection] = useState({});
    const [docLoaded, setDocLoaded] = useState(false);
    const [undoStack, setUndoStack] = useState([]);
    const [viewVersion, setViewVersion] = useState(0);
    const [viewVsVersion, setViewVsVersion] = useState(0);
    const [markupMode, setMarkupMode] = useState(true);
    const markupColors = ["#479EC9", "#B95045", "#954F72", "#385E85", "#EBC743", "#969696", "#2C8084"];
    const textColors = ["#ffffff", "#ffffff", "#ffffff", "#ffffff", "#ffffff", "#ffffff", "#ffffff"];
    const [timeoutID, setTimeoutID] = useState(null);
    const timeoutIDRef = useRef();
    timeoutIDRef.current = timeoutID;
    const [showComments, setShowComments] = useState(true);
    const [insertTimeoutRef, setInsertTimeoutRef] = useState(null);
    const [insertBuffer, setInsertBuffer] = useState("");
    const navigate = useNavigate();

    // useEffect(() => {
    //     return;
    // }, [editorState]);

    useEffect(() => {
        console.log("use effect docview");
        //console.log(docState);
        if (!docLoaded) {
            //console.log("try load...");
            const docRef = doc(db, "docs", loadData.docid);
            getDoc(docRef).then((docSnap) => {
                if (docSnap.exists()) {
                    console.log("...data exists");
                    var data = docSnap.data();

                    // if (loadData.docid.includes("_TEMPLATE")) {
                    //     setDoc(doc(db, "docs", loadData.docid + "2"), data);
                    // }

                    //cleanData(data);
                    //console.log(data);
                    var ver = data.versionlog[data.version];

                    if (data.status == "expired") {
                        navigate("/expired");
                        return;
                    }
                    console.log(data);
                    setDocState(data);
                    setDocLoaded(true);

                    //simplify?
                    setActiveColor(data.contributors[data.ownerid].color);
                    setViewVersion(data.version);
                    setSubjectField(data.versionlog[data.version].subject);
                    if (data.draftMessage) {
                        setMessageField(data.draftMessage);
                    }
                    setToField(data.versionlog[data.version].toid);

                    setMarkupMode(data.version > 0);
                    //setMarkupMode(true);

                    let ro = data.status != "draft" || ver.status != "draft";
                    setRedlineReadOnly(ro);
                    if (ro) {
                        statusMessage("Read-only", "lock");
                    } else {
                        statusMessage("Ready", "check");
                    }
                }
            });
        }
    }, []);

    function cleanData(data) {
        for (let node of data.content.root.children) {
            if (node.type == "custom-paragraph") {
                node.charData = {};
                for (let childNode of node.children) {
                    if (childNode.type == "text") {
                        childNode.style = "";
                        // let styleData = parseStyleData(childNode.style);
                        // let c = styleData["--char-data"];
                        // if (c && c.versions) {
                        //     let vData = c.versions[c.versions.length - 1];
                        //     if (vData.status == "added") {
                        //         //delete
                        //         console.log(vData);
                        //         childNode.text = "";
                        //         childNode.style = "";
                        //     } else {
                        //         childNode.style = "";
                        //     }
                        // }
                    }
                }
            }
        }
    }

    function saveDoc() {
        //check if send is occuring
        const ds = docStateRef.current;
        statusMessage("Saving...", "pending");
        console.log("save doc");
        console.log(ds);
        setDoc(doc(db, "docs", ds.docid), ds).then(() => {
            statusMessage("Saved", "check");
        }).catch((e) => {
            statusMessage("Save failed", "error");
            console.log(e);
        });
        // setDoc(doc(db, "docs", ds.docid + "_TEMPLATE"), ds).then(() => {
        //     statusMessage("Saved", "check");
        // }).catch((e) => {
        //     statusMessage("Save failed", "error");
        //     console.log(e);
        // });
    }

    function statusMessage(msgText, msgType) {
        if (msgType == "check") {
            setStatusIcon(<CheckCircleIcon fontSize="small" color="success" />);
        } else if (msgType == "error") {
            setStatusIcon(<ErrorIcon fontSize="small" color="error" />);
        } else if (msgType == "pending") {
            setStatusIcon(<AutoRenewIcon fontSize="small" color="disabled" />);
        } else if (msgType == "send") {
            setStatusIcon(<SendIcon fontSize="small" color="primary" />);
        } else if (msgType == "lock") {
            setStatusIcon(<LockIcon fontSize="small" color="warning" />);
        }
        setStatusLabel(msgText);
    }

    function changesPending() {
        statusMessage("Unsaved changes", "pending");
    }

    function onChangeBlackline(newEditorState) {
        console.log("New editorstate received up to docview");
        //console.log(JSON.stringify(docState, 2));
        //console.log(JSON.stringify(docStateRef.current, 2));

        docStateRef.current.content = newEditorState;
        //setDocState(newDocState);
    }

    function sendDoc() {
        //abort auto save if pending
        if (timeoutIDRef.current) {
            clearTimeout(timeoutIDRef.current);
        }

        setRedlineReadOnly(true);
        statusMessage("Sending...", "pending");

        const batch = writeBatch(db);

        var docObj = JSON.parse(JSON.stringify(docState));
        docObj.status = "sent";
        docObj.messages.push({
            color: activeColor,
            text: docObj.draftMessage ?? "",
            timestamp: Date.now(),
        })

        if (docObj.draftMessage) {
            delete docObj.draftMessage;
        }

        batch.set(doc(db, "docs", docObj.docid), docObj);

        const eventObj = { "docid": docObj.docid, "action": "send" };
        batch.set(doc(db, "events", docObj.docid), eventObj);

        batch.commit().then(() => {
            statusMessage("Document sent", "check");
            var docObj = JSON.parse(JSON.stringify(docState));
            docObj.status = "sent";
            docObj.versionlog[docState.version].status = "sent";
            //setDocState(docObj);
            //redirect to sent page
            //TO DO add url params with after send info
            navigate("/aftersend");

        }).catch((e) => {
            console.log(e);
            statusMessage("Send failed", "error");
            setRedlineReadOnly(false);
        });
    }

    function executeDoc() {
        //abort auto save if pending
        if (timeoutIDRef.current) {
            clearTimeout(timeoutIDRef.current);
        }

        setRedlineReadOnly(true);
        statusMessage("Executing...", "pending");

        const batch = writeBatch(db);

        var docObj = JSON.parse(JSON.stringify(docState));
        //docObj.status = "sent";

        batch.set(doc(db, "docs", docObj.docid), docObj);

        const eventObj = { "docid": docObj.docid, "action": "pdf" };
        batch.set(doc(db, "events", docObj.docid), eventObj);

        batch.commit().then(() => {
            statusMessage("Document sent", "check");
            //docState.status = "sent";
            //docState.versionlog[docState.version].status = "sent";
            //setDocState(docState);
            //redirect to sent page
            //TO DO add url params with after send info
            //navigate("/aftersend");
            setRedlineReadOnly(false);
        }).catch((e) => {
            console.log(e);
            statusMessage("Execute failed", "error");
            setRedlineReadOnly(false);
        });
    }

    function contentChange() {
        changesPending();
        console.log("docstate - content change");

        if (timeoutIDRef.current) {
            clearTimeout(timeoutIDRef.current);
        }
        setTimeoutID(setTimeout(() => {
            saveDoc();
        }, 1500));
    }

    function toFieldChange(event, value) {
        setToField(value);
        if (docState.versionlog.length > 0) {
            docState.versionlog[docState.version].toid = value;
            setDocState(docState);
            contentChange();
        }
    }

    function subjectFieldChange(event) {
        let value = event.target.value;
        setSubjectField(value);
        if (docState.versionlog.length > 0) {
            docState.versionlog[docState.version].subject = value;
            setDocState(docState);
            contentChange();
        }
    }

    function messageFieldChange(event) {
        let value = event.target.value;
        setMessageField(value);
        docState.draftMessage = value;
        setDocState(docState);
        contentChange();
    }

    function createNewVersion() {
        //create new version for editing

        let ds = docStateRef.current;
        let verNum = ds.version + 1;
        let ver = ds.versionlog[ds.version];
        if (ver) {
            ds.version = verNum;
            ds.status = "draft";
            ds.versionlog.push({
                timestamp: Date.now(),
                fromid: ds.ownerid,
                toid: ver.fromid,
                subject: ver.subject,
                status: "draft",
            });
            setDocState(ds);
            setRedlineReadOnly(false);
            console.log("create new version ", ds.verNum + 1);
            setViewVersion(verNum);
            setViewVsVersion(0);
            setMarkupMode(true);
            contentChange();
        }
    }

    function onVersionSelect(version) {
        var ver = docState.versionlog[docState.version];
        if (version > docState.version) {
            //create new version for editing
            docState.version++;
            docState.status = "draft";
            docState.versionlog.push({
                timestamp: Date.now(),
                fromid: docState.ownerid,
                toid: ver.fromid,
                subject: ver.subject,
                status: "draft",
            });
            setDocState(docState);
            setRedlineReadOnly(false);
            contentChange();
        } else if (version < docState.version) {
            setRedlineReadOnly(true);
        } else {
            setRedlineReadOnly(docState.status != "draft" || ver.status != "draft");
        }
        setViewVersion(version);
        setViewVsVersion(0);
        // if (version < viewVsVersion) {
        //   setViewVsVersion(version);
        // 
    }

    function viewVsVersionSelect(version) {
        setViewVsVersion(version);
    }

    return (
        <div>
            <header>
                {/* <a href="https://lexical.dev" target="_blank" rel="noreferrer">
                <img src={logo} alt="eNDA Logo" />
              </a> */}
                <div style={{ "padding-top": 20 }}>
                    <Typography variant="h4" sx={{ "fontWeight": "bold", "fontStyle": "italic", "color": "#fff" }}>eNDA</Typography>
                </div>
            </header>
            <div className="Editor-shell">
                <Blackline
                    docState={docState}
                    viewVersion={viewVersion}
                    setViewVersion={setViewVersion}
                    viewVsVersion={viewVsVersion}
                    setViewVsVersion={setViewVsVersion}
                    markupMode={markupMode}
                    activeColor={activeColor}
                    readOnly={redlineReadOnly}
                    undoStack={undoStack}
                    setUndoStack={setUndoStack}
                    markupColors={markupColors}
                    textColors={textColors}
                    //selection={selection}
                    onChange={onChangeBlackline}
                    contentChange={contentChange}
                    statusIcon={statusIcon}
                    statusLabel={statusLabel}
                    toField={toField}
                    toFieldChange={toFieldChange}
                    subjectField={subjectField}
                    subjectFieldChange={subjectFieldChange}
                    messageField={messageField}
                    messageFieldChange={messageFieldChange}
                    sendDoc={sendDoc}
                    createNewVersion={createNewVersion}
                    showComments={showComments} />


                {/* <Box width="100%" pt={8}>
                    <Grid container spacing={1} p={3}>
                        <Grid item xl={2.5}>
                            <Box className="RightBar">
                                <Stack direction="row" spacing={1}>
                                    {statusIcon}
                                    <Typography sx={{ color: statusColor }}>{statusLabel} </Typography>
                                </Stack>
                                <br />
                                <VsVersionSelector data={docState.versionlog} version={docState.version} vsVersion={viewVsVersion} onSelect={viewVsVersionSelect} />
                                <br />
                                <FormGroup>
                                    <FormControlLabel control={<Switch defaultChecked />} label={"Show Comments"} />
                                </FormGroup>
                                <br />
                                <UserChips contributors={docState.contributors} markupColors={markupColors} />
                                <br />
                                <ReadOnlyIndicator readOnly={redlineReadOnly} />
                            </Box>
                        </Grid>

                        <Grid container item spacing={1} xl={9.5}>
                            <Grid item xl={8}>
                                <SummaryBox docState={docState} viewVersion={viewVersion} onVersionSelect={onVersionSelect} />
                            </Grid>
                            <Grid item xl={4}></Grid>

                            <Grid item xl={12}>
                                <Blackline
                                    docState={docState}
                                    viewVersion={viewVersion}
                                    viewVsVersion={viewVsVersion}
                                    markupMode={true}
                                    activeColor={activeColor}
                                    readOnly={redlineReadOnly}
                                    undoStack={undoStack}
                                    setUndoStack={setUndoStack}
                                    markupColors={markupColors}
                                    textColors={textColors}
                                    selection={selection}
                                    onChange={onChangeBlackline}
                                    contentChange={contentChange}
                                    onChangeSelection={onChangeSelection}
                                    showComments={showComments} />
                            </Grid>
                            <Grid item xl={8}>
                                <SendBox
                                    docState={docState}
                                    viewVersion={viewVersion}
                                    sendDoc={sendDoc}
                                    executeDoc={executeDoc}
                                    toFieldChange={toFieldChange}
                                    subjectField={subjectField}
                                    subjectFieldChange={subjectFieldChange}
                                    messageField={messageField}
                                    messageFieldChange={messageFieldChange}
                                    readOnly={redlineReadOnly} />
                            </Grid>
                            <Grid item xl={4} >
                            </Grid>
                        </Grid>
                    </Grid>
                </Box> */}
            </div >
        </div >
    );
}

export async function docviewLoader({ params }) {
    // console.log(JSON.stringify(cleanDoc,2));
    // var d = JSON.parse(JSON.stringify(cleanDoc));
    // var allChars = [];
    // for (let p in d.paragraphs) {
    //     for (let c in p.charData) {
    //         allChars.push(c);
    //     }
    // }
    // d.charData = allChars;
    // delete d.paragraphs;
    //setDoc(doc(db, "docs", cleanDoc.docid), cleanDoc);
    // console.log("EXPORT");
    // console.log(JSON.stringify(d, 2));
    // console.log("FINISH EXPORT");

    return { docid: params.docid };
}

const emptyDoc = {
    status: "",
    subject: "",
    ownerid: "",
    projectid: "",
    projectname: "",
    versionlog: [],
    contributors: {},
};
