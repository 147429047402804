import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
    LexicalContextMenuPlugin,
    MenuOption,
} from '@lexical/react/LexicalContextMenuPlugin';
import {
    $getSelection,
    $isRangeSelection,
    $isTextNode,
    COPY_COMMAND,
    CUT_COMMAND,
    PASTE_COMMAND,
} from 'lexical';
import { useCallback, useMemo, useEffect } from 'react';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { getVisibleVersionData, parseStyleData, ACCEPT_COMMAND, REJECT_COMMAND, UNDO_DELETE_COMMAND } from './Blackline';

function ContextMenuItem({
    index,
    isSelected,
    onClick,
    onMouseEnter,
    option,
}) {
    let className = 'item';
    if (isSelected) {
        className += ' selected';
    }
    return (
        <li
            key={option.key}
            tabIndex={-1}
            className={className}
            ref={option.setRefElement}
            role="option"
            aria-selected={isSelected}
            id={'typeahead-item-' + index}
            onMouseEnter={onMouseEnter}
            onClick={onClick}>
            <span className="text">{option.title}</span>
        </li>
    );
}

function ContextMenu({
    options,
    selectedItemIndex,
    onOptionClick,
    onOptionMouseEnter,
}) {
    return (
        <div className="typeahead-popover">
            <ul>
                {options.map((option, i) => (
                    <ContextMenuItem
                        index={i}
                        isSelected={selectedItemIndex === i}
                        onClick={() => onOptionClick(option, i)}
                        onMouseEnter={() => onOptionMouseEnter(i)}
                        key={option.key}
                        option={option}
                    />
                ))}
            </ul>
        </div>
    );
}

export class ContextMenuOption extends MenuOption {
    constructor(title, options) {
        super(title);
        this.title = title;
        this.onSelect = options.onSelect.bind(this);
    }
}

export function ContextMenuPlugin(props) {
    const [editor] = useLexicalComposerContext();

    let options = [];
    let optionNames = [];

    let editorState = editor.getEditorState();
    editorState.read(() => {
        //console.log("context!");
        const selection = $getSelection();
        if ($isRangeSelection(selection) && !props.readOnly) {
            const selectedNodes = selection.getNodes();
            for (const currentNode of selectedNodes) {
                if ($isTextNode(currentNode)) {
                    const styleData = parseStyleData(currentNode.getStyle());
                    const charData = styleData["--char-data"];
                    if (charData) {
                        const vData = getVisibleVersionData(charData, props.viewVersion, props.viewVsVersion);
                        if (vData) {
                            if (!optionNames.includes("Accept") &&
                                (vData.status == "added" || vData.status == "deleted") &&
                                vData.color != props.activeColor) {
                                //accept other users adds/deletes
                                //TODO - only accept for counter party edits
                                options.push(new ContextMenuOption(`Accept`, {
                                    onSelect: (_node) => {
                                        editor.dispatchCommand(ACCEPT_COMMAND, null);
                                    },
                                }));
                                optionNames.push("Accept");
                                options.push(new ContextMenuOption(`Reject`, {
                                    onSelect: (_node) => {
                                        editor.dispatchCommand(REJECT_COMMAND, null);
                                    },
                                }));
                                optionNames.push("Reject");
                            } else if (!optionNames.includes("Undo Delete") && vData.status == "deleted" && vData.color == props.activeColor) {
                                options.push(new ContextMenuOption(`Undo Delete`, {
                                    onSelect: (_node) => {
                                        editor.dispatchCommand(UNDO_DELETE_COMMAND, null);
                                    },
                                }));
                                optionNames.push("Undo Delete");
                            }
                        }
                    }
                }
            }
        }
    });
    options.push(new ContextMenuOption(`Copy`, {
        onSelect: (_node) => {
            editor.dispatchCommand(COPY_COMMAND, null);
        },
    }));
    if (!props.readOnly) {
        options.push(new ContextMenuOption(`Cut`, {
            onSelect: (_node) => {
                editor.dispatchCommand(CUT_COMMAND, null);
            },
        }));
    }
    // return [
    //     new ContextMenuOption(`Copy`, {
    //         onSelect: (_node) => {
    //             editor.dispatchCommand(COPY_COMMAND, null);
    //         },
    //     }),
    //     new ContextMenuOption(`Cut`, {
    //         onSelect: (_node) => {
    //             editor.dispatchCommand(CUT_COMMAND, null);
    //         },
    //     }),
    //     new ContextMenuOption(`Paste`, {
    //         onSelect: (_node) => {
    //             navigator.clipboard.read().then(async (...args) => {
    //                 const data = new DataTransfer();

    //                 const items = await navigator.clipboard.read();
    //                 const item = items[0];

    //                 const permission = await navigator.permissions.query({
    //                     // @ts-ignore These types are incorrect.
    //                     name: 'clipboard-read',
    //                 });
    //                 if (permission.state === 'denied') {
    //                     alert('Not allowed to paste from clipboard.');
    //                     return;
    //                 }

    //                 for (const type of item.types) {
    //                     const dataString = await (await item.getType(type)).text();
    //                     data.setData(type, dataString);
    //                 }

    //                 const event = new ClipboardEvent('paste', {
    //                     clipboardData: data,
    //                 });

    //                 editor.dispatchCommand(PASTE_COMMAND, event);
    //             });
    //         },
    //     }),
    //     new ContextMenuOption(`Paste as Plain Text`, {
    //         onSelect: (_node) => {
    //             navigator.clipboard.read().then(async (...args) => {
    //                 const permission = await navigator.permissions.query({
    //                     // @ts-ignore These types are incorrect.
    //                     name: 'clipboard-read',
    //                 });

    //                 if (permission.state === 'denied') {
    //                     alert('Not allowed to paste from clipboard.');
    //                     return;
    //                 }

    //                 const data = new DataTransfer();
    //                 const items = await navigator.clipboard.readText();
    //                 data.setData('text/plain', items);

    //                 const event = new ClipboardEvent('paste', {
    //                     clipboardData: data,
    //                 });
    //                 editor.dispatchCommand(PASTE_COMMAND, event);
    //             });
    //         },
    //     }),
    // ];

    const onSelectOption = useCallback(
        (
            selectedOption,
            targetNode,
            closeMenu,
        ) => {
            editor.update(() => {
                selectedOption.onSelect(targetNode);
                closeMenu();
            });
        },
        [editor],
    );

    return (
        <LexicalContextMenuPlugin
            options={options}
            onSelectOption={onSelectOption}
            menuRenderFn={(
                anchorElementRef,
                {
                    selectedIndex,
                    options,
                    selectOptionAndCleanUp,
                    setHighlightedIndex,
                },
                { setMenuRef },
            ) =>
                anchorElementRef.current
                    ? ReactDOM.createPortal(
                        <div
                            className="typeahead-popover auto-embed-menu"
                            style={{
                                marginLeft: anchorElementRef.current.style.width,
                                userSelect: 'none',
                                width: 200,
                            }}
                            ref={setMenuRef}>
                            <ContextMenu
                                options={options}
                                selectedItemIndex={selectedIndex}
                                onOptionClick={(option, index) => {
                                    setHighlightedIndex(index);
                                    selectOptionAndCleanUp(option);
                                }}
                                onOptionMouseEnter={(index) => {
                                    setHighlightedIndex(index);
                                }}
                            />
                        </div>,
                        anchorElementRef.current,
                    )
                    : null
            }
        />
    );
}