import {
    $getSelectionStyleValueForProperty,
    $isParentElementRTL,
    $patchStyleText,
    $setBlocksType,
} from '@lexical/selection';
import { $isTableNode } from '@lexical/table';
import {
    $findMatchingParent,
    $getNearestBlockElementAncestorOrThrow,
    $getNearestNodeOfType,
    mergeRegister,
} from '@lexical/utils';
import {
    $createParagraphNode,
    $getNodeByKey,
    $getRoot,
    $getSelection,
    $isElementNode,
    $isRangeSelection,
    $isRootOrShadowRoot,
    $isTextNode,
    CAN_REDO_COMMAND,
    CAN_UNDO_COMMAND,
    COMMAND_PRIORITY_CRITICAL,
    COMMAND_PRIORITY_NORMAL,
    DEPRECATED_$isGridSelection,
    ElementFormatType,
    FORMAT_ELEMENT_COMMAND,
    FORMAT_TEXT_COMMAND,
    INDENT_CONTENT_COMMAND,
    KEY_MODIFIER_COMMAND,
    LexicalEditor,
    NodeKey,
    OUTDENT_CONTENT_COMMAND,
    REDO_COMMAND,
    SELECTION_CHANGE_COMMAND,
    UNDO_COMMAND,
} from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { Dispatch, useCallback, useEffect, useState } from 'react';
import { getSelectedNode } from './getSelectedNode';
import { SET_FONT_COMMAND, SET_FONT_SIZE_COMMAND, parseStyleData, getVisibleVersionData, defaultFontFamily, getContributorByColor } from './Blackline';

import Select from '@mui/material/Select'
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import InfoIcon from '@mui/icons-material/Info';
import FormatIndentDecreaseIcon from '@mui/icons-material/FormatIndentDecrease';
import FormatIndentIncreaseIcon from '@mui/icons-material/FormatIndentIncrease';
import Tooltip from '@mui/material/Tooltip';
import LockIcon from '@mui/icons-material/Lock';
import { ButtonGroup, Typography } from '@mui/material';
import * as React from 'react';

const allEqual = arr => arr.every(val => val === arr[0]);

function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export default function StatusBarPlugin(props) {
    const [editor] = useLexicalComposerContext();
    const [activeEditor, setActiveEditor] = useState(editor);
    const [isEditable, setIsEditable] = useState(() => editor.isEditable());

    const $updateToolbar = useCallback(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            const selectedNodes = selection.getNodes();
            if (!selectedNodes) return;
            const selectedTextNodes = [];
            for (const selectedNode of selectedNodes) {
                if ($isTextNode(selectedNode)) {
                    selectedTextNodes.push(selectedNode);
                }
            }

            const selectedTextNodesLength = selectedTextNodes.length;
            if (selectedTextNodesLength === 0) return;

            // let selectedFontSizes = [];
            // let selectedUserColors = [];
            // let selectedFontFamily = false;
            // let selectedFormats = false;
            // let selectedAlignments = [];

            // for (let textNode of selectedTextNodes) {
            //     let style = textNode.getStyle();
            //     let styleData = style ? parseStyleData(style) : {};

            //     let c = styleData["--char-data"];
            //     let ignoreNode = false;
            //     if (c && c.versions) {
            //         if (getVisibleVersionData(c, props.viewVersion, props.viewVsVersion)) {
            //             let vData = c.versions[c.versions.length - 1];
            //             let fs = vData.fontSize ? vData.fontSize : "medium";
            //             selectedFontSizes.push(fs);
            //             if (["added", "deleted", "added accepted"].includes(vData.status)) {
            //                 selectedUserColors.push(vData.color);
            //             }
            //             if (!selectedFontFamily) {
            //                 setFontFamily(vData.fontFamily ? vData.fontFamily : defaultFontFamily);
            //                 selectedFontFamily = true;
            //             }
            //             if (!selectedFormats) {
            //                 setFormats(vData.formats ? vData.formats : []);
            //                 selectedFormats = true;
            //             }
            //         } else {
            //             ignoreNode = true;
            //         }
            //     } else {
            //         selectedFontSizes.push("medium");
            //         setFontFamily(defaultFontFamily);
            //         setFormats([]);
            //     }

            //     if (!ignoreNode) {
            //         //get alignment from parent node
            //         const element = $findMatchingParent(
            //             textNode,
            //             (parentNode) =>
            //                 $isElementNode(parentNode) && !parentNode.isInline(),
            //         );
            //         if (element !== null && element.getType() == "custom-paragraph") {
            //             let charData = element.getCharData();
            //             if (charData.versions && charData.versions.length > 0) {
            //                 let f = charData.versions[charData.versions.length - 1].format;
            //                 if (f) selectedAlignments.push(f);
            //             }
            //         }
            //     }
            // }

            // if (allEqual(selectedUserColors)) {
            //     let userID = getContributorByColor(selectedUserColors[0], props.contributors);
            //     props.setTooltipMessage(userID);
            //     props.setTooltipColor(props.markupColors[selectedUserColors[0]]);
            // }

            // if (allEqual(selectedFontSizes)) {
            //     setFontSize(selectedFontSizes[0]);
            // } else {
            //     setFontSize("");
            // }

            // if (selectedAlignments.length == 0) {
            //     setAlignment("justify");
            // } else if (allEqual(selectedAlignments)) {
            //     setAlignment(selectedAlignments[0]);
            // } else {
            //     setAlignment("");
            // }

        }
    }, [activeEditor]);

    useEffect(() => {
        return editor.registerCommand(
            SELECTION_CHANGE_COMMAND,
            (_payload, newEditor) => {
                $updateToolbar();
                setActiveEditor(newEditor);
                return false;
            },
            COMMAND_PRIORITY_CRITICAL,
        );
    }, [editor, $updateToolbar]);

    useEffect(() => {
        return mergeRegister(
            editor.registerEditableListener((editable) => {
                setIsEditable(editable);
            }),
            activeEditor.registerUpdateListener(({ editorState }) => {
                editorState.read(() => {
                    $updateToolbar();
                });
            }),
        );
    }, [$updateToolbar, activeEditor, editor]);


    let ver = props.docState.versionlog[props.docState.version];
    let verDescriptor = "";
    if (ver) {
        let dt = new Date(ver.timestamp);

        if (ver.status == "draft") {
            verDescriptor = "Draft dated ";
        } else if (ver.status == "sent" || ver.status == "received") {
            verDescriptor = "Version " + ver.status + " ";
        } else if (ver.status == "executed") {
            verDescriptor = "Document executed ";
        }
        verDescriptor += dt.toDateString();
    }

    return (
        <div className="statusbar" >
            {window.innerWidth > 600 ?
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={2}>
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={1}
                    >
                        <Typography sx={{ fontSize: 14 }}>{verDescriptor} </Typography>
                        {/* <Typography sx={{ fontSize: 14 }}>{"Version by:"} </Typography>
                <Typography sx={{ fontSize: 14, fontWeight: "bold", color: props.curUserColor }}>{props.curUser}</Typography> */}
                    </Stack>
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={1}
                    >
                        <Typography sx={{ fontSize: 14, color: props.tooltipColor }}>{props.tooltipMessage} </Typography>
                        <Typography sx={{ fontSize: 14, fontWeight: "bold", color: props.tooltipColor }}>{props.tooltipUser} </Typography>
                    </Stack>
                </Stack>
                : ""}
            <span style={{ flexGrow: 1 }} />
            <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={1}
            >
                <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>{props.statusLabel} </Typography>
                {props.statusIcon}
            </Stack>
        </div >
    );
}