import React from 'react';
//import './docview.css';

import Container from '@mui/material/Container';
import Button from '@mui/material/Button';

export default function Expired() {
  return (
    <div className="App">
      <header className="App-header">
        <Container maxWidth="xl">
          <h1>This link has expired</h1>
          <Button variant="contained">
            Email me a new link
        </Button>
        </Container>
      </header>
    </div>
  );
}
