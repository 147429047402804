import React, { useState, useEffect } from 'react';
//import './docview.css';

import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';

export default function Create() {
  const [templateData, setTemplateData] = useState([]);

  useEffect(() => {
    //load template data (async)
  });

  return (
    <div className="App">
      <header className="App-header">
        <Box sx={{ maxWidth: "lg" }} width="100%" pt={10}>
          <Grid style={{ margin: 0, width: '100%' }} container spacing={3} id="maingrid">
            <Grid item xs={6}>
              <Stack direction="column" spacing={10}>
                <Typography fontFamily="inherit" variant="h5" fontWeight="bold">Use a Template</Typography>


              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack direction="column" spacing={10}>
                <Typography fontFamily="inherit" variant="h5" fontWeight="bold">Upload your own</Typography>
                <Typography fontFamily="inherit">Coming soon!</Typography>
              </Stack>
            </Grid>

            {/* <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
                  <Typography fontFamily="inherit" variant="h6" fontWeight="bold">NEW NDA</Typography>
                </Grid>
                <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
                  <Typography fontFamily="inherit">Subject</Typography>
                </Grid>
                <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
                  <Typography fontFamily="inherit">To</Typography>
                </Grid>
                <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
                  <Typography fontFamily="inherit">From</Typography>
                </Grid>
                <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
                  <Typography fontFamily="inherit">Template</Typography>
                </Grid>
                <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
                  <Typography fontFamily="inherit">Template</Typography>
                </Grid> */}
            {/* <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
                    <Divider />
                  </Grid>
                  <Grid item xs={2} style={{ display: "flex", alignItems: "center" }}>
                    <Typography fontFamily="inherit">Disclosing party</Typography>
                  </Grid>
                  <Grid item xs={10} style={{ display: "flex", alignItems: "center" }}>
                    <TextField sx={{ input: { color: "#1769aa", fontWeight: "bold" } }} id="recipient" defaultValue="" variant="standard" fullWidth />
                  </Grid>
                  <Grid item xs={2} style={{ display: "flex", alignItems: "center" }}>
                    <Typography fontFamily="inherit">Recipient entity</Typography>
                  </Grid>
                  <Grid item xs={10} style={{ display: "flex", alignItems: "center" }}>
                    <TextField sx={{ input: { color: "#1769aa", fontWeight: "bold" } }} id="recipient" defaultValue="" variant="standard" fullWidth />
                  </Grid>
                  <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
                    <Divider />
                  </Grid>
                  <Grid item xs={2} style={{ display: "flex", alignItems: "center" }}>
                    <Typography fontFamily="inherit">Term</Typography>
                  </Grid>
                  <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
                    <Slider
                      aria-label="Term"
                      defaultValue={24}
                      valueLabelDisplay="on"
                      step={1}
                      min={1}
                      max={48} />
                  </Grid>
                  <Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                      <InputLabel id="demo-simple-select-standard-label"></InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        label="Term"
                        value="M"
                      >
                        <MenuItem value={"M"}>Months</MenuItem>
                        <MenuItem value={"Y"}>Years</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2} style={{ display: "flex", justifyContent: "flex-start" }}>
                    <Typography fontFamily="inherit">Representatives</Typography>
                  </Grid>
                  <Grid item xs={10} style={{ display: "flex", alignItems: "center" }}>
                    <Stack direction="column" spacing={1}>
                      <Stack direction="row" spacing={1}>
                        <Chip label="current or prospective investors" onDelete={handleChipDelete} />
                        <Chip label="debt financing sources" onDelete={handleChipDelete} />
                      </Stack>
                      <Stack direction="row" spacing={1}>
                        <Chip label="directors" onDelete={handleChipDelete} />
                        <Chip label="officers" onDelete={handleChipDelete} />
                        <Chip label="employees" onDelete={handleChipDelete} />
                        <Chip label="agents" onDelete={handleChipDelete} />
                      </Stack>
                      <Stack direction="row" spacing={1}>
                        <Chip label="advisors" onDelete={handleChipDelete} />
                        <Chip label="consultants" onDelete={handleChipDelete} />
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item xs={2} style={{ display: "flex", alignItems: "center" }}>
                    <Typography fontFamily="inherit">Governing law</Typography>
                  </Grid>
                  <Grid item xs={10} style={{ display: "flex", alignItems: "center" }}>
                    <TextField id="governing-law" sx={{ input: { color: "#1769aa", fontWeight: "bold" } }} defaultValue="State of New York" variant="standard" fullWidth />
                  </Grid> */}
          </Grid>
        </Box>
      </header>
    </div>
  );
}

