import React from 'react';
import { Outlet, useNavigate } from "react-router-dom";

import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
//import Menu from '@mui/material/Menu';
//import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
//import AdbIcon from '@mui/icons-material/Adb';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SearchIcon from '@mui/icons-material/Search';
import SendIcon from '@mui/icons-material/Send';
import Badge from '@mui/material/Badge';
import MoreIcon from '@mui/icons-material/MoreVert';
import Slider from '@mui/material/Slider';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import SvgIcon from '@mui/material/SvgIcon';
import CloseIcon from '@mui/icons-material/CancelPresentationRounded';
import UndoIcon from '@mui/icons-material/Undo';
import MinimizeIcon from '@mui/icons-material/Minimize';
import AddIcon from '@mui/icons-material/AddCircleOutlineRounded';
import AddCommentIcon from '@mui/icons-material/AddComment';

import './root.css';

export default function Root() {
  const navigate = useNavigate();
  return (
    <div className="App">
        {/* <Box sx={{flexGrow: 1}}>
          <AppBar>
            <Container maxWidth="xl">
              <Toolbar disableGutters>
                <Typography
                  variant="h6"
                  noWrap
                  component="a"
                  href="/"
                  sx={{
                    mr: 2,
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    letterSpacing: '.3rem',
                    color: 'inherit',
                    textDecoration: 'none',
                    paddingRight: 2,
                  }}
                >
                  eNDA
                </Typography>
                <Button sx={{ my: 2, color: 'white', display: 'block', paddingRight: 5 }} onClick={() => navigate("/create")}>
                  CREATE
                </Button>
                <Button
                  sx={{ my: 2, color: 'white', display: 'block', paddingRight: 5 }}
                >
                  DASHBOARD
                </Button>
                <Box sx={{ flexGrow: 1 }} />

                <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                  <IconButton
                      size="large"
                      aria-label="search"
                      color="inherit"
                    >
                      <SearchIcon />
                    </IconButton>
                    <IconButton
                      size="large"
                      aria-label="show 0 new notifications"
                      color="inherit"
                    >
                      <Badge badgeContent={0} color="error">
                        <NotificationsIcon />
                      </Badge>
                    </IconButton>
                  <IconButton
                    size="large"
                    edge="end"
                    aria-label="account of current user"
                    color="inherit"
                  >
                    <Avatar alt="Alex M" src="face.jpg">AM</Avatar>
                    <Avatar alt="" src=""></Avatar>
                  </IconButton>
                </Box>
                <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                  <IconButton
                    size="large"
                    aria-label="show more"
                    aria-haspopup="true"
                    color="inherit"
                  >
                    <MoreIcon />
                  </IconButton>
                </Box>

              </Toolbar>
            </Container>
          </AppBar>
        </Box> */}
        <Outlet />
    </div >
  );
}
