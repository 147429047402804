import React, { useState, useEffect } from 'react';
//import './docview.css';

import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';

export default function AfterSend() {
  return (
    <div className="App">
      <header className="App-header">
        <Container maxWidth="xl">
          <h1>Document sent - Thank you for using eNDA</h1>
        </Container>
      </header>
    </div>
  );
}
