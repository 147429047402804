import {
    $getSelectionStyleValueForProperty,
    $isParentElementRTL,
    $patchStyleText,
    $setBlocksType,
} from '@lexical/selection';
import { $isTableNode } from '@lexical/table';
import {
    $findMatchingParent,
    $getNearestBlockElementAncestorOrThrow,
    $getNearestNodeOfType,
    mergeRegister,
} from '@lexical/utils';
import {
    $createParagraphNode,
    $getNodeByKey,
    $getRoot,
    $getSelection,
    $isElementNode,
    $isRangeSelection,
    $isRootOrShadowRoot,
    $isTextNode,
    CAN_REDO_COMMAND,
    CAN_UNDO_COMMAND,
    COMMAND_PRIORITY_CRITICAL,
    COMMAND_PRIORITY_NORMAL,
    DEPRECATED_$isGridSelection,
    ElementFormatType,
    FORMAT_ELEMENT_COMMAND,
    FORMAT_TEXT_COMMAND,
    INDENT_CONTENT_COMMAND,
    KEY_MODIFIER_COMMAND,
    LexicalEditor,
    NodeKey,
    OUTDENT_CONTENT_COMMAND,
    REDO_COMMAND,
    SELECTION_CHANGE_COMMAND,
    UNDO_COMMAND,
} from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { Dispatch, useCallback, useEffect, useState } from 'react';
import { getSelectedNode } from './getSelectedNode';
import { SET_FONT_COMMAND, SET_FONT_SIZE_COMMAND, parseStyleData, getVisibleVersionData, defaultFontFamily, getContributorByColor } from './Blackline';

import Select from '@mui/material/Select'
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import InfoIcon from '@mui/icons-material/Info';
import FormatIndentDecreaseIcon from '@mui/icons-material/FormatIndentDecrease';
import FormatIndentIncreaseIcon from '@mui/icons-material/FormatIndentIncrease';
import Tooltip from '@mui/material/Tooltip';
import { ButtonGroup } from '@mui/material';
import * as React from 'react';

const allEqual = arr => arr.every(val => val === arr[0]);

export default function ToolbarPlugin(props) {
    const [editor] = useLexicalComposerContext();
    const [activeEditor, setActiveEditor] = useState(editor);
    const [blockType, setBlockType] = useState('paragraph');
    const [rootType, setRootType] = useState('root');
    const [selectedElementKey, setSelectedElementKey] = useState(null);
    const [fontSize, setFontSize] = useState("medium");
    const [fontFamily, setFontFamily] = useState(defaultFontFamily);
    const [alignment, setAlignment] = useState("justify");
    const [elementFormat, setElementFormat] = useState('left');
    const [formats, setFormats] = useState([]);
    const [isBold, setIsBold] = useState(false);
    const [isItalic, setIsItalic] = useState(false);
    const [isUnderline, setIsUnderline] = useState(false);
    const [canUndo, setCanUndo] = useState(false);
    const [canRedo, setCanRedo] = useState(false);
    const [isEditable, setIsEditable] = useState(() => editor.isEditable());

    //const $updateToolbar = useCallback(() => {
    const $updateToolbar = () => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            const selectedNodes = selection.getNodes();
            if (!selectedNodes) return;
            const selectedTextNodes = [];
            for (const selectedNode of selectedNodes) {
                if ($isTextNode(selectedNode)) {
                    selectedTextNodes.push(selectedNode);
                }
            }

            const selectedTextNodesLength = selectedTextNodes.length;
            if (selectedTextNodesLength === 0) return;

            let selectedFontSizes = [];
            let selectedUserColors = [];
            let selectedFontFamily = false;
            let selectedFormats = false;
            let selectedAlignments = [];

            for (let textNode of selectedTextNodes) {
                let style = textNode.getStyle();
                let styleData = style ? parseStyleData(style) : {};

                let c = styleData["--char-data"];
                let ignoreNode = false;
                if (c && c.versions) {
                    //console.log(c);
                    let vData = getVisibleVersionData(c, props.viewVersion, props.viewVsVersion);
                    if (vData) {
                        let fs = vData.fontSize ? vData.fontSize : "medium";
                        selectedFontSizes.push(fs);
                        if (["added", "deleted", "added accepted"].includes(vData.status)) {
                            selectedUserColors.push(vData.color);
                        }
                        if (!selectedFontFamily) {
                            setFontFamily(vData.fontFamily ? vData.fontFamily : defaultFontFamily);
                            selectedFontFamily = true;
                        }
                        if (!selectedFormats) {
                            setFormats(vData.formats ? vData.formats : []);
                            selectedFormats = true;
                        }
                    } else {
                        ignoreNode = true;
                    }
                } else {
                    selectedFontSizes.push("medium");
                    setFontFamily(defaultFontFamily);
                    setFormats([]);
                }

                if (!ignoreNode) {
                    //get alignment from parent node
                    const element = $findMatchingParent(
                        textNode,
                        (parentNode) =>
                            $isElementNode(parentNode) && !parentNode.isInline(),
                    );
                    if (element !== null && element.getType() == "custom-paragraph") {
                        let charData = element.getCharData();
                        if (charData.versions && charData.versions.length > 0) {
                            let f = charData.versions[charData.versions.length - 1].format;
                            if (f) selectedAlignments.push(f);
                        }
                    }
                }
            }

            // if (allEqual(selectedUserColors)) {
            //     let userID = getContributorByColor(selectedUserColors[0], props.contributors);
            //     props.setTooltipMessage(userID);
            //     props.setTooltipColor(props.markupColors[selectedUserColors[0]]);
            // }

            // console.log("selected::");
            // console.log(selectedFontSizes);
            if (selectedFontSizes.length == 0) {
                setFontSize("medium");
            } else if (allEqual(selectedFontSizes)) {
                setFontSize(selectedFontSizes[0]);
            } else {
                setFontSize("");
            }

            if (selectedAlignments.length == 0) {
                setAlignment("justify");
            } else if (allEqual(selectedAlignments)) {
                setAlignment(selectedAlignments[0]);
            } else {
                setAlignment("");
            }
        }
    };
    //}, [activeEditor]);

    useEffect(() => {
        return editor.registerCommand(
            SELECTION_CHANGE_COMMAND,
            (_payload, newEditor) => {
                $updateToolbar();
                setActiveEditor(newEditor);
                return false;
            },
            COMMAND_PRIORITY_CRITICAL,
        );
    }, [editor, $updateToolbar]);

    useEffect(() => {
        return mergeRegister(
            editor.registerEditableListener((editable) => {
                setIsEditable(editable);
            }),
            activeEditor.registerUpdateListener(({ editorState }) => {
                editorState.read(() => {
                    $updateToolbar();
                });
            }),
            activeEditor.registerCommand(
                CAN_UNDO_COMMAND,
                (payload) => {
                    setCanUndo(payload);
                    return false;
                },
                COMMAND_PRIORITY_CRITICAL,
            ),
            activeEditor.registerCommand(
                CAN_REDO_COMMAND,
                (payload) => {
                    setCanRedo(payload);
                    return false;
                },
                COMMAND_PRIORITY_CRITICAL,
            ),
        );
    }, [$updateToolbar, activeEditor, editor]);

    const applyStyleText = useCallback(
        (styles) => {
            activeEditor.update(() => {
                const selection = $getSelection();
                if (
                    $isRangeSelection(selection) ||
                    DEPRECATED_$isGridSelection(selection)
                ) {
                    $patchStyleText(selection, styles);
                }
            });
        },
        [activeEditor],
    );

    const versionMenuItems = [];
    for (let v = 0; v <= props.docState.version; v++) {
        versionMenuItems.push(<MenuItem value={v}>{v + 1}</MenuItem>);
    }

    const viewVsVersionMenuItems = [];
    var label;
    for (let v = 0; v <= props.viewVersion; v++) {
        if (v == props.viewVersion) {
            label = "Clean";
        } else if (v == 0) {
            label = "Cumulative";
        } else {
            label = "vs. v" + (v + 1).toString();
        }
        viewVsVersionMenuItems.push(<MenuItem value={v}>{label}</MenuItem>);
    }

    let verData = props.docState.versionlog[props.docState.version];
    let editButton = verData ? verData.status == "received" : false;

    return (
        <div className="Toolbar" >
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1} sx={{ "padding-left": 10 }}>
                <IconButton aria-label="delete">
                    <MenuIcon />
                </IconButton>
                {/* <button
            disabled={false}
            onClick={() => {
              // activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
            }}
            className={'toolbar-item spaced '}
            type="button"
          >
            <i className="format menu" />
          </button> */}
                <FormControl size="small" sx={{ minWidth: 100 }}>
                    <InputLabel id="fontlabel">Font</InputLabel>
                    <Select
                        disabled={props.readOnly}
                        autowidth
                        labelId="fontlabel"
                        id="demo-simple-select"
                        value={fontFamily}
                        label="font"
                        onChange={(event) => {
                            //console.log(event.target.value);
                            setFontFamily(event.target.value);
                            editor.dispatchCommand(SET_FONT_COMMAND, event.target.value);
                        }}
                    >
                        <MenuItem value={defaultFontFamily}>Modern</MenuItem>
                        <MenuItem value={"Georgia, Bookman Old Style, serif"}>Classic</MenuItem>
                    </Select>
                </FormControl>
                <FormControl size="small" sx={{ minWidth: 100 }}>
                    <InputLabel id="fontsize">Size</InputLabel>
                    <Select
                        disabled={props.readOnly}
                        autowidth
                        labelId="fontsize"
                        id="demo-simple-select"
                        value={fontSize}
                        label="size"
                        onChange={(event) => {
                            editor.dispatchCommand(SET_FONT_SIZE_COMMAND, event.target.value);
                            setFontSize(event.target.value);
                        }}
                    >
                        <MenuItem value={"tiny"}>Tiny</MenuItem>
                        <MenuItem value={"small"}>Small</MenuItem>
                        <MenuItem value={"medium"}>Medium</MenuItem>
                        <MenuItem value={"large"}>Large</MenuItem>
                        <MenuItem value={"x-large"}>XL</MenuItem>
                    </Select>
                </FormControl>
                <ToggleButtonGroup
                    disabled={props.readOnly}
                    value={formats}
                    aria-label="text formatting"
                    size="small"
                    onChange={(event, newFormats) => {
                        for (let f of ["bold", "italic", "underline"]) {
                            if (newFormats.includes(f) != formats.includes(f)) {
                                editor.dispatchCommand(FORMAT_TEXT_COMMAND, f);
                            }
                        }
                        setFormats(newFormats);
                    }}
                >
                    <ToggleButton value="bold" aria-label="bold" sx={{ "border": 0 }}>
                        <FormatBoldIcon />
                    </ToggleButton>
                    <ToggleButton value="italic" aria-label="italic" sx={{ "border": 0 }}>
                        <FormatItalicIcon />
                    </ToggleButton>
                    <ToggleButton value="underline" aria-label="underline" sx={{ "border": 0 }}>
                        <FormatUnderlinedIcon />
                    </ToggleButton>
                </ToggleButtonGroup>

                <ToggleButtonGroup
                    disabled={props.readOnly}
                    value={alignment}
                    exclusive
                    aria-label="alignment"
                    size="small"
                    onChange={(event, value) => {
                        setAlignment(value);
                        editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, value);
                    }}
                >
                    <ToggleButton value="left" aria-label="left aligd" sx={{ "border": 0 }}>
                        <FormatAlignLeftIcon />
                    </ToggleButton>
                    <ToggleButton value="center" aria-label="center" sx={{ "border": 0 }}>
                        <FormatAlignCenterIcon />
                    </ToggleButton>
                    <ToggleButton value="right" aria-label="right align" sx={{ "border": 0 }}>
                        <FormatAlignRightIcon />
                    </ToggleButton>
                    <ToggleButton value="justify" aria-label="justified" sx={{ "border": 0 }}>
                        <FormatAlignJustifyIcon />
                    </ToggleButton>
                </ToggleButtonGroup>

                <ButtonGroup aria-label="indent">
                    <IconButton disabled={props.readOnly} aria-label="indent left"
                        onClick={() => {
                            editor.dispatchCommand(OUTDENT_CONTENT_COMMAND);
                        }}>
                        <FormatIndentDecreaseIcon />
                    </IconButton>
                    <IconButton disabled={props.readOnly} aria-label="indent right"
                        onClick={() => {
                            editor.dispatchCommand(INDENT_CONTENT_COMMAND);
                        }}>
                        <FormatIndentIncreaseIcon />
                    </IconButton>
                </ButtonGroup>

                <FormControl size="small">
                    <InputLabel id="versionlabel">Version</InputLabel>
                    <Select
                        labelId="versionlabel"
                        id="demo-simple-select"
                        value={props.viewVersion}
                        label="version"
                        sx={{ "width": "75px" }}
                        onChange={(event) => {
                            //TODO add new version
                            props.setViewVersion(event.target.value);
                            if (event.target.value > props.viewVsVersion) {
                                props.setViewVsVersion(0);
                            }
                        }}
                    >
                        {versionMenuItems}
                    </Select>
                </FormControl>
                <FormControl size="small">
                    <InputLabel id="markuplabel">Markup</InputLabel>
                    <Select
                        autowidth
                        labelId="markuplabel"
                        id="demo-simple-select"
                        value={props.viewVsVersion}
                        label="Markup"
                        sx={{ "minWidth": "75px" }}
                        onChange={(event) => {
                            props.setViewVsVersion(event.target.value);
                        }}
                    >
                        {viewVsVersionMenuItems}
                    </Select>
                </FormControl>
            </Stack>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1} sx={{ "width": "100%", "padding-right": 10, "margin-left": 10 }}>
                {editButton ?
                    <Button
                        variant="contained"
                        onClick={() => {
                            props.createNewVersion();
                        }}
                    >Edit</Button> : ""
                }
                <Button
                    disabled={props.readOnly}
                    variant="contained"
                    onClick={() => {
                        props.setSendDialogOpen(true);
                    }}
                    endIcon={<SendIcon />}>Send</Button>
            </Stack>
        </div>
    );
}