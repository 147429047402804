import { ParagraphNode } from "lexical";

export class CustomParagraphNode extends ParagraphNode {
    __charData;

    constructor() {
        super();
        this.__charData = {};
    }

    static getType() {
        return "custom-paragraph";
    }

    static clone(node) {
        return new CustomParagraphNode(node.__key);
    }

    createDOM(config) {
        const dom = super.createDOM(config);
        return dom;
    }

    getCharData() {
        return this.__charData;
    }

    setCharData(charData) {
        const self = this.getWritable();
        self.__charData = charData;
    }

    exportJSON() {
        return {
            ...super.exportJSON(),
            charData: this.__charData,
            type: "custom-paragraph",
            version: 1
        };
    }

    static importJSON(serializedNode) {
        const node = $createCustomParagraphNode();
        node.setFormat(serializedNode.format);
        node.setIndent(serializedNode.indent);
        node.setDirection(serializedNode.direction);
        node.setCharData(serializedNode.charData);
        return node;
    }
}

export function $createCustomParagraphNode() {
    return new CustomParagraphNode();
}

export function $isCustomParagraphNode(node) {
    return node instanceof CustomParagraphNode;
}