import {
    $getSelectionStyleValueForProperty,
    $isParentElementRTL,
    $patchStyleText,
    $setBlocksType,
} from '@lexical/selection';
import { $isTableNode } from '@lexical/table';
import {
    $findMatchingParent,
    $getNearestBlockElementAncestorOrThrow,
    $getNearestNodeOfType,
    mergeRegister,
} from '@lexical/utils';
import {
    $createParagraphNode,
    $getNodeByKey,
    $getRoot,
    $getSelection,
    $isElementNode,
    $isRangeSelection,
    $isRootOrShadowRoot,
    $isTextNode,
    CAN_REDO_COMMAND,
    CAN_UNDO_COMMAND,
    COMMAND_PRIORITY_CRITICAL,
    COMMAND_PRIORITY_NORMAL,
    DEPRECATED_$isGridSelection,
    ElementFormatType,
    FORMAT_ELEMENT_COMMAND,
    FORMAT_TEXT_COMMAND,
    INDENT_CONTENT_COMMAND,
    KEY_MODIFIER_COMMAND,
    LexicalEditor,
    NodeKey,
    OUTDENT_CONTENT_COMMAND,
    REDO_COMMAND,
    SELECTION_CHANGE_COMMAND,
    UNDO_COMMAND,
} from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { Dispatch, useCallback, useEffect, useState } from 'react';
import { getSelectedNode } from './getSelectedNode';
import { SET_FONT_COMMAND, SET_FONT_SIZE_COMMAND, parseStyleData, getVisibleVersionData, defaultFontFamily, getContributorByColor } from './Blackline';

import Select from '@mui/material/Select'
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import InfoIcon from '@mui/icons-material/Info';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CancelIcon from '@mui/icons-material/Cancel';
import FormatIndentDecreaseIcon from '@mui/icons-material/FormatIndentDecrease';
import FormatIndentIncreaseIcon from '@mui/icons-material/FormatIndentIncrease';
import Tooltip from '@mui/material/Tooltip';
import LockIcon from '@mui/icons-material/Lock';
import { ButtonGroup, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import * as React from 'react';
import { Check } from '@mui/icons-material';

//const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const emailRegex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export default function SendDialogPlugin(props) {
    const [editor] = useLexicalComposerContext();
    const [activeEditor, setActiveEditor] = useState(editor);
    const [error, setError] = useState(false);
    const [errorText, setErrorText] = useState("");

    if (!props.docState.contributors) return;

    var toOptions = [];
    for (let [userID, userData] of Object.entries(props.docState.contributors)) {
        if (userID != props.docState.ownerid) {
            toOptions.push(userID);
        }
    }


    return (
        <React.Fragment>
            <Dialog
                open={props.open}
                maxWidth={"md"}
                fullWidth={true}
            >
                <DialogTitle>Send Document</DialogTitle>
                <DialogContent>
                    <Stack
                        direction="column"
                        justifyContent="flex-end"
                        alignItems="flex-start"
                        spacing={2}
                    >
                        <div />
                        <Autocomplete
                            size="small"
                            autoComplete
                            freeSolo
                            disablePortal
                            id="combo-box-to"
                            value={props.toField}
                            onInputChange={props.toFieldChange}
                            options={toOptions}
                            renderInput={(params) => <TextField {...params} label="Send to" error={error} helperText={errorText} />}
                            sx={{ width: 1, marginTop: "10px" }}
                        />
                        <TextField size="small" label="Subject" onChange={props.subjectFieldChange} value={props.subjectField} fullWidth />
                        <TextField size="small" label="Message" multiline minRows={6} onChange={props.messageFieldChange} value={props.messageField} fullWidth />
                    </Stack>
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={2}
                        sx={{ marginTop: "20px" }}
                    >
                        <FormGroup sx={{ paddingLeft: "10px" }}>
                            <FormControlLabel control={<Checkbox />} disabled label="Ready to Execute" />
                        </FormGroup>
                    </Stack>
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="flex-start"
                        spacing={2}
                    >
                        {/* <span style={{ flexGrow: 1 }} /> */}
                        <Button variant="outlined" onClick={() => { props.setOpen(false); }}>Cancel</Button>
                        <Button variant="contained" endIcon={<SendIcon />}
                            onClick={() => {
                                //verify email format
                                //adopt signature if needed
                                //send callback

                                console.log(props.toField);
                                if (!props.toField.match(emailRegex)) {
                                    setErrorText("Invalid email address");
                                    setError(true);
                                } else {
                                    setErrorText("");
                                    setError(false);
                                    props.setOpen(false);
                                    props.sendDoc();
                                }
                            }}
                        >Send</Button>
                    </Stack>
                </DialogContent>
                {/* <DialogActions>
                    <Button onClick={() => { props.setOpen(false); }}>Cancel</Button>
                    <Button type="submit">Send</Button>
                </DialogActions> */}
            </Dialog>
        </React.Fragment >
    );
}